import { createContext, useContext, useState } from "react";
import { firebase, provider } from "../firebase/config";
import { API_URL } from "../Services";

export const GlobalContext = createContext();

const GlobalProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isLogin, setIsLogin] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);
  const [showScrollUp, setShowScrollUp] = useState(false);
  const [categories, setCategories] = useState([]);
  const [socialMedia, setSocialMedia] = useState();
  const [role, setRole] = useState("");
  const [user, setUser] = useState();
  const noImage = "/assets/images/No Image.png";

  function helper(request, authorization) {
    let option = {
      method: "POST",
      headers: {
        authorization,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...request }),
    };
    return fetch(API_URL + "entry", option)
      .then(async (response) => {
        let data = await response.json();
        let statusCode = response.status;
        let codes = [100, 200, 201];
        let result = { status: statusCode, data };
        const token = data.data.key.token;
        if (!token) return Promise.reject("Failed to get token");
        setUser(data.data);
        localStorage.setItem("authorization", token);
        if (!codes.includes(statusCode)) {
          return Promise.reject(result);
        }
        return Promise.resolve(result);
      })
      .catch((err) => {
        throw err;
      });
  }

  function loginFirebase(providerString, action) {
    if (!(providerString && action))
      throw "providerString & action is required!";
    return firebase
      .auth()
      .signInWithPopup(provider[providerString])
      .then(async ({ user, additionalUserInfo }) => {
        let token = await user.getIdToken(false);
        return { token, profile: additionalUserInfo.profile };
      })
      .then(({ token, profile }) => {
        let authorization = `Bearer ${token}`;
        let { email, first_name, last_name, given_name, family_name } = profile;
        let requestBody = { action, email, provider: providerString };
        if (action === "register") {
          requestBody = {
            ...requestBody,
            first_name: first_name || given_name, // FB : first_name || Google : given_name
            last_name: last_name || family_name, // FB : last_name || Google : family_name
            image_profile:
              providerString === "facebook"
                ? profile.picture.data.url
                : profile.picture,
          };
        } // note dapat di handle di page saja, setelah success response.
        return helper(requestBody, authorization);
      });
  }

  function logout() {
    let token = localStorage.getItem("authorization") || undefined;
    if (token) {
      if (token.startsWith("Bearer ")) firebase.auth().signOut();
      localStorage.removeItem("authorization");
    }
    setIsLogin(false);
    setUser(undefined);
  }

  async function getProfile() {
    try {
      let response = await fetch(API_URL + "auth/user", {
        method: "GET",
        headers: { authorization: localStorage.getItem("authorization") },
      });
      let data = await response.json();
      return data;
    } catch (error) {
      throw error;
    }
  }

  const values = {
    isLoading,
    setIsLoading,
    isLogin,
    setIsLogin,
    categories,
    setCategories,
    showSidebar,
    setShowSidebar,
    showScrollUp,
    setShowScrollUp,
    loginFirebase,
    role,
    setRole,
    logout,
    getProfile,
    noImage,
    user,
    setUser,
    socialMedia,
    setSocialMedia,
  };

  return (
    <GlobalContext.Provider value={values}>{children}</GlobalContext.Provider>
  );
};

export const useGlobalContext = () => useContext(GlobalContext);

export default GlobalProvider;
