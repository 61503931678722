import axios from "axios";

export const API_URL = process.env.REACT_APP_API_URL
  ? process.env.REACT_APP_API_URL + "/v1/"
  : "/v1/";

export interface ContentType {
  id: number;
  name: string;
  status: string;
  createdAt: string;
  updatedAt: string;
  limited_part: number;
  parts: any[];
}

export interface ProfileType {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  role: string;
  phone_number: string;
  image_profile: string;
  createdAt: string;
  updatedAt: string;
  customer: {
    id: number;
    user_id: number;
    status: string;
    createdAt: string;
    updatedAt: string;
  };
}

export interface EquipmentType {
  photos: string[];
  id: number;
  vendor_id: number;
  name: string;
  slug: string;
  type: string;
  category_id: number;
  brand_id: number;
  price: number;
  old_price: null;
  discount: number;
  description: string;
  condition: string;
  serial_number: string;
  model: string;
  year: number;
  power_rating: number;
  hour_meter: number;
  min_rental_period: number;
  min_rental_period_type: string;
  min_working_hours: number;
  location: string;
  rental_area: string;
  video_url: string;
  is_available_sale: boolean;
  is_wishlist: number;
  wishlist_id: number;
  status: string;
  created_at: string;
  updated_at: string;
  category_based_properties: string;
  category_based_properties_value: string;
  category: {
    id: number;
    name: string;
    slug: string;
    parent_id: number;
    level: number;
    createdAt: string;
    updatedAt: string;
  };
  brand: {
    id: number;
    title: string;
  };
  vendor: {
    id: number;
    user_id: number;
    company_type: string;
    name: string;
    status: null;
    address: string;
    province: null;
    city: string;
    created_at: string;
    updated_at: string;
    user: {
      image_profile: string;
      id: number;
      email: string;
      first_name: string;
      last_name: string;
      phone_number: string;
    };
  };
}

export interface CategoryType {
  id: number;
  name: string;
  slug: string;
  parent_id: number;
  level: number;
  createdAt: string;
  image: string;
  updatedAt: string;
  category_based_properties: string | null;
  category_based_properties_value: string | null;
  rent_period: any[];
  subcategories: SubCategoryType[];
  parent: SubCategoryType | null;
  total_equipment: number;
}

export interface SubCategoryType {
  id: number;
  name: string;
  slug: string;
  parent_id: number;
  level: number;
  createdAt: string;
  updatedAt: string;
  category_based_properties: string | null;
  category_based_properties_value: string | null;
  rent_period: any[];
  total_equipment: number;
  image: string
}

export interface BrandType {
  content: {};
  id: number;
  type: string;
  title: string;
  slug: string;
  image: any;
  status: string;
  created_at: string;
  updated_at: string;
  total_equipment: number;
}

export const loginService = (data: any) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API_URL + "login", data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => reject(err));
  });
};

export const registerService = (data: any) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API_URL + "register", data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => reject(err));
  });
};

export const contentsService = (contentId: number) => {
  return new Promise((resolve, reject) => {
    axios
      .get(API_URL + "contents/" + contentId)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => reject(err));
  });
};

export const getService = (
  endpoint: string,
  params?: any,
  token?: string | null
) => {
  return new Promise((resolve, reject) => {
    axios
      .get(API_URL + endpoint, {
        params: params,
        headers: token
          ? {
              authorization: token.startsWith("Bearer")
                ? token
                : "Bearer " + token,
            }
          : {},
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => reject(err));
  });
};

export const putService = (endpoint: string, data?: any, token?: string) => {
  return new Promise((resolve, reject) => {
    axios
      .put(API_URL + endpoint, data, {
        headers: token
          ? {
              authorization: token.startsWith("Bearer")
                ? token
                : "Bearer " + token,
            }
          : {},
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => reject(err));
  });
};
export const patchService = (endpoint: string, data?: any, token?: string) => {
  return new Promise((resolve, reject) => {
    axios
      .patch(API_URL + endpoint, data, {
        headers: token
          ? {
              authorization: token.startsWith("Bearer")
                ? token
                : "Bearer " + token,
            }
          : {},
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => reject(err));
  });
};

export const postService = (endpoint: string, data: any, token?: string) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API_URL + endpoint, data, {
        headers: token
          ? {
              authorization: token.startsWith("Bearer")
                ? token
                : "Bearer " + token,
            }
          : {},
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => reject(err));
  });
};

export const deleteService = (
  endpoint: string,
  params?: any,
  token?: string
) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(API_URL + endpoint, {
        params: params,
        headers: token
          ? {
              authorization: token.startsWith("Bearer")
                ? token
                : "Bearer " + token,
            }
          : {},
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => reject(err));
  });
};
