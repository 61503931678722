import { useEffect, lazy, Suspense } from "react";
import { isExpired } from "react-jwt";
import { Routes, Route, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Loading from "./Components/Loading";
import { useGlobalContext } from "./context/GlobalContext";
const DashboardLayout = lazy(() => import("./Layouts/DashboardLayout"));
const PageLayout = lazy(() => import("./Layouts/PageLayout"));
const AboutUS = lazy(() => import("./Pages/AboutUS"));
const PersonalInformation = lazy(() =>
  import("./Pages/Accounts/PersonalInformation")
);
const Settings = lazy(() => import("./Pages/Accounts/Settings"));
const ConfirmEmailSignUp = lazy(() =>
  import("./Pages/Auth/ConfirmEmailSignUp")
);
const EmailLinkExpired = lazy(() => import("./Pages/Auth/EmailLinkExpired"));
const EmailLinkSuccess = lazy(() => import("./Pages/Auth/EmailLinkSuccess"));
const ForgotPassword = lazy(() => import("./Pages/Auth/ForgotPassword"));
const LinkExpired = lazy(() => import("./Pages/Auth/LinkExpired"));
const Login = lazy(() => import("./Pages/Auth/Login"));
const Register = lazy(() => import("./Pages/Auth/Register"));
const SetNewPassword = lazy(() => import("./Pages/Auth/SetNewPassword"));
const BecomeAVendor = lazy(() => import("./Pages/BecomeAVendor"));
const DetailListing = lazy(() => import("./Pages/DetailListing"));
const NotFound = lazy(() => import("./Pages/Error/NotFound"));
const Home = lazy(() => import("./Pages/Home"));
const Listing = lazy(() => import("./Pages/Listing"));
const ListingType = lazy(() => import("./Pages/ListingType/ListingType"));
const RequestEquipment = lazy(() => import("./Pages/RequestEquipment"));
const User = lazy(() => import("./Pages/user"));
const Vendor = lazy(() => import("./Pages/vendor"));
const PrivacyPolicy = lazy(() => import("./Pages/PrivacyPolicy"));
const TermsOfUse = lazy(() => import("./Pages/TermsOfUse"));

function MustLogin({ children }) {
  const { setIsLogin, logout } = useGlobalContext();
  const token = localStorage.getItem("authorization") || undefined;
  let navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      logout();
      return navigate("/login");
    }
    if (token && isExpired(token)) {
      logout();
      return navigate("/login");
    }
    setIsLogin(true);
  }, []);

  return children;
}

function OnlyGuest({ children }) {
  const { setIsLogin, logout } = useGlobalContext();
  const token = localStorage.getItem("authorization") || undefined;
  let navigate = useNavigate();

  useEffect(() => {
    if (token) {
      if (!isExpired(token)) {
        setIsLogin(true);
        return navigate("/");
      }
    } else {
      logout();
    }
  }, []);
  return children;
}

function App() {
  const { isLoading } = useGlobalContext();

  return (
    <>
      <Suspense fallback={<Loading />}>
        {isLoading && <Loading />}
        <Routes>
          {/* Home */}
          <Route
            index
            element={
              <PageLayout title="Home">
                <Home />
              </PageLayout>
            }
          />
          {/* About US */}
          <Route
            path="/about-us"
            element={
              <PageLayout title="About US">
                <AboutUS />
              </PageLayout>
            }
          />
          {/* Terms of Use */}
          <Route
            path="/terms-of-use"
            element={
              <PageLayout title="Terms of Use">
                <TermsOfUse />
              </PageLayout>
            }
          />
          {/* Privacy Policy */}
          <Route
            path="/privacy-policy"
            element={
              <PageLayout title="Privacy Policy">
                <PrivacyPolicy />
              </PageLayout>
            }
          />
          {/* Not Logged In */}
          <Route
            path="/login"
            element={
              <OnlyGuest>
                <PageLayout title="Login">
                  <Login />
                </PageLayout>
              </OnlyGuest>
            }
          />
          <Route
            path="/register"
            element={
              <OnlyGuest>
                <PageLayout title="Register">
                  <Register />
                </PageLayout>
              </OnlyGuest>
            }
          />
          <Route
            path="/confirm-email"
            element={
              <PageLayout title="Confirm your email">
                <ConfirmEmailSignUp />
              </PageLayout>
            }
          />
          <Route
            path="/confirm-email-success"
            element={
              <PageLayout title="Email Confirmation Success">
                <EmailLinkSuccess />
              </PageLayout>
            }
          />
          <Route
            path="/confirm-email-expired"
            element={
              <PageLayout title="Email Confirmation Expired">
                <EmailLinkExpired />
              </PageLayout>
            }
          />
          <Route
            path="/forgot-password"
            element={
              <PageLayout title="Forgot Password">
                <ForgotPassword />
              </PageLayout>
            }
          />
          <Route
            path="/set-new-password"
            element={
              <PageLayout title="Set New Password">
                <SetNewPassword />
              </PageLayout>
            }
          />
          <Route
            path="/recovery-link-expired"
            element={
              <PageLayout title="Recovery Link Expired">
                <LinkExpired />
              </PageLayout>
            }
          />
          <Route
            path="listing"
            element={
              <PageLayout title="Listing Equipments">
                <Listing />
              </PageLayout>
            }
          />

          <Route
            path="/listing/:slug"
            element={
              <PageLayout title={`Listing`}>
                <DetailListing />
              </PageLayout>
            }
          />
          <Route
            path="/request-equipment/:type/:listingId"
            element={
              <PageLayout title="Request Equipment Rent">
                <RequestEquipment />
              </PageLayout>
            }
          />
          <Route
            path="/request-equipment/:type/:listingId"
            element={
              <PageLayout title="Request Equipment Buy">
                <RequestEquipment />
              </PageLayout>
            }
          />
          <Route
            path="/select-listing-type"
            element={
              <PageLayout title="Select Listing Type">
                <ListingType />
              </PageLayout>
            }
          />
          <Route
            path="/become-a-vendor"
            element={
              <PageLayout title="Become a Vendor">
                <BecomeAVendor />
              </PageLayout>
            }
          />
          {/* End Not Logged In */}

          {/* Logged In User */}
          <Route
            path="/account/personal-information"
            element={
              <MustLogin>
                <PageLayout title="Personal Information">
                  <PersonalInformation />
                </PageLayout>
              </MustLogin>
            }
          />
          <Route
            path="/account/settings"
            element={
              <MustLogin>
                <PageLayout title="Accounts Settings">
                  <Settings />
                </PageLayout>
              </MustLogin>
            }
          />
          {/* End Logged In User */}

          {/* Dashboard Pages */}
          <Route
            path="/user/*"
            element={
              <MustLogin>
                <DashboardLayout title="Dashboard">
                  <User />
                </DashboardLayout>
              </MustLogin>
            }
          />
          <Route
            path="/vendor/*"
            element={
              <MustLogin>
                <DashboardLayout title="Dashboard">
                  <Vendor />
                </DashboardLayout>
              </MustLogin>
            }
          />
          {/* End Dashboard */}
          <Route
            path="*"
            element={
              <PageLayout title="404 Page Not Found">
                <NotFound />
              </PageLayout>
            }
          />
        </Routes>
        {/* <button
          className={classNames(
            "fixed bottom-8 right-8 bg-[#4b4a4a] hover:bg-[#000128] h-8 w-8 rounded-full flex items-center justify-center text-white transition-all duration-300 ease-in-out",
            showScrollUp
              ? "opacity-100 pointer-events-auto"
              : "opacity-0 pointer-events-none"
          )}
          onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
        >
          <i className="fas fa-arrow-up"></i>
        </button> */}
        <ToastContainer pauseOnFocusLoss={false} />
      </Suspense>
    </>
  );
}

export default App;
